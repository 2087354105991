<template>
  <q-page class="" padding>
    <on-demand-form
      :options="options"
      :location-query="airportSearch"
      :mapper="airportMapper"
      :user="user"
      :initial="initial"
      :labels="labels"
      :saved="false"
      type="fas fa-plane"
      @submit="onSubmit"
    />
  </q-page>
</template>

<script>
import date from 'utils/date-time'
import OnDemandForm from '../form/'
import { airportMapper } from '../form/mappers'
import { airports as airportSearch } from 'api/flights'
import { mapGetters } from 'vuex'
import model from '../flights/model.js'

export default {
  components: {
    OnDemandForm
  },
  data () {
    return {
      labels: {
        location: {
          origin: this.$t('going_from'),
          destination: this.$t('going_to'),
          icons: {
            origin: 'fa fa-plane-departure',
            destination: 'fa fa-plane-arrival'
          }
        },
        submit: this.$tc('find.flight')
      },
      initial: {
        departMin: date.newDate(),
        origin: {},
        options: {
          class: 'Economy',
          max_changes: 'direct'
        }
      },
      options: {
        class: [
          {
            label: this.$t('flight.class.economy'),
            value: 'Economy'
          },
          {
            label: this.$t('flight.class.premium_economy'),
            value: 'PremiumEconomy'
          },
          {
            label: this.$t('flight.class.business'),
            value: 'Business'
          },
          {
            label: this.$t('flight.class.first'),
            value: 'First'
          }
        ],
        max_changes: [
          {
            label: this.$tc('flight.changes', 0),
            value: 'direct'
          },
          {
            label: this.$tc('flight.changes', 1),
            value: '1'
          },
          {
            label: this.$tc('flight.changes', 2, { num: '2+' }),
            value: null
          }
        ]
      },
      returning: true,
      locationResults: null,
      activeInput: ''
    }
  },
  computed: {
    ...mapGetters({ user: 'userLookup' }),
    disabled () {
      if (!this.parameters.origin || !this.parameters.destination) {
        return true
      }
      return false
    },
    people: {
      get () {
        return {
          traveller: this.parameters.traveller,
          requester: this.parameters.requester
        }
      },
      set (val) {
        this.parameters.requester = val.requester
        this.parameters.traveller = val.traveller
      }
    }
  },
  watch: {
    '$route.params.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.initial.origin = val
        }
      }
    }
  },
  methods: {
    airportMapper,
    airportSearch,
    onSubmit (values) {
      // class: this.parameters.options.cabinClass,
      // max_changes: this.parameters.options.maxChanges
      let mappedValues = {
        ...model(),
        ...values,
        outbound: values.date.outbound,
        inbound: values.date.inbound
      }
      this.$store.dispatch('ondemand/stash', { parameters: mappedValues })
      this.$router.push({ name: 'ondemand-flights-outbound' })
    }
  }
}
</script>
